<template>
  <div class="home">
    <div class="MenuContents">
      <div class="menubox enterprisecolor" @click="to('/realname/enterprise')">
        企业实名
      </div>
      <div class="menubox personagecolor" @click="to('/realname/personage')">
        个人实名
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  methods: {
    to(url) {
      console.log(url)
      this.$router.push({ path: url })
    }
  }
}
</script>
<style scoped>

  .home{
    max-width: 400px;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('../assets/backgroundimg.jpeg');
  }
  .clearfix{
    text-align: left;
  }
  .MenuContents{
    display: flex;

    height: 200px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .menubox {
    width: 150px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    border-radius: 10px;
    cursor: pointer;
  }
  .enterprisecolor{
    color: white;
    background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
  }
  .personagecolor{
    color: white;
    background-image: linear-gradient(to top, #4481eb 0%, #04befe 100%);
  }
</style>
