import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import TuvcertHome from '../views/tuvcert/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '实名认证通道' }
  },
  {
    path: '/tuvcertmenu',
    name: 'TuvcertHome',
    component: TuvcertHome,
    meta: { title: '实名认证通道' }
  },
  {
    path: '/realname',
    name: 'realname',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/realname/navigation'),
    meta: { title: '实名认证通道' },
    children: [
      {
        path: 'enterprise',
        component: () => import('../views/realname/enterprise'),
        name: 'enterprise',
        meta: { title: '企业实名' }
      },
      {
        path: 'personage',
        component: () => import('../views/realname/personage'),
        name: 'personage',
        meta: { title: '个人实名' }
      },
      {
        path: 'EnterpriseSucess',
        component: () => import('../views/realname/Success/EnterpriseSucess'),
        name: 'EnterpriseSucess',
        meta: { title: '企业实名完成' }
      },
      {
        path: 'PersonageSucess',
        component: () => import('../views/realname/Success/PersonageSucess'),
        name: 'PersonageSucess',
        meta: { title: '个人实名完成' }
      }
    ]
  },
  {
    path: '/tuvcert',
    name: 'tuvcert',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/tuvcert/navigation'),
    meta: { title: '实名认证通道' },
    children: [
      {
        path: 'enterprise',
        component: () => import('../views/tuvcert/enterprise'),
        name: 'enterprise',
        meta: { title: '企业实名' }
      },
      {
        path: 'personage',
        component: () => import('../views/tuvcert/personage'),
        name: 'personage',
        meta: { title: '个人实名' }
      },
      {
        path: 'EnterpriseSucess',
        component: () => import('../views/realname/Success/EnterpriseSucess'),
        name: 'EnterpriseSucess',
        meta: { title: '企业实名完成' }
      },
      {
        path: 'PersonageSucess',
        component: () => import('../views/realname/Success/PersonageSucess'),
        name: 'PersonageSucess',
        meta: { title: '个人实名完成' }
      }
    ]
  }

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
