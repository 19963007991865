import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Input,
  Form,
  FormItem,
  Col,
  Button,
  Loading,
  Message,
  Step,
  Steps,
  Checkbox,
  link,
  Drawer,
  Card
} from 'element-ui'

Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Col)
Vue.use(Loading.directive)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Checkbox)
Vue.use(link)
Vue.use(Drawer)
Vue.use(Card)

Vue.prototype.$message = Message
Vue.config.productionTip = false
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
